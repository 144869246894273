import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import localization from '../../../localization';
import { isCardedOptions } from '../../../utils/experimentConstants';
import {
  formatPrice,
  getPriceWithCurrencySymbol,
  optimizeTourImages,
} from '../../../utils/helpers';
import { Divider } from '../../Common';
import CommonButton from '../../Common/Button';
import Footer from '../../Footer';
import Header from '../../Header';
import LocationBanner from '../../Location/Banner';
import PoweredBy from '../../PoweredBy';
import TourFeatures from '../TourFeatures';
import TourItinerary from '../TourItinerary';
import { Description, Inclusions, Map, Text, ViatorMap } from '../TourUtils/index';

const arrow_back = require('../../../assets/img/arrow-25-back-xxl.png');
const arrow_forward = require('../../../assets/img/arrow-25-xxl.png');

export const BoldFont = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
`;

const useStyles = makeStyles({
  wrapper: {
    background: isCardedOptions() ? '#f6f7fa' : '#fff',
  },
  shortTourDesc: {
    lineHeight: '24px',
  },
  introContainer: {
    width: '90vw',
    maxWidth: '1208px',
    textAlign: 'left',
    marginTop: '24px',
    margin: 'auto',
  },
  arrowStyles: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    border: 'none',
    color: 'none',
    backgroundColor: 'none',
  },
  backgroundImage: {
    width: 'auto!important',
    maxWidth: '1000px',
    maxHeight: '386px',
    position: 'relative',
    zIndex: '1',
  },
  blurredBackground: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    objectFit: 'cover',
    filter: 'blur(8px)',
  },
  commonButton: {
    borderRadius: '7px',
    minHight: '32px',
    minWidth: '140px',
    fontWeight: 'bold',
    lineHeight: '1.3',
    padding: '7px 17px',
    whiteSpace: 'nowrap',
  },
  filteredImageContainer: {
    maxHeight: '386px',
    objectFit: 'contain',
  },
});

let carouselHeight = null;

export default function TourDetailsBase({ tour, bookingWidget, reviewWidget }) {
  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const scrollRef = useRef();
  console.log('Logging tour');
  useEffect(
    function initTourDetailsBase() {
      const resizer = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', resizer);

      if (screenWidth < 720) {
        carouselHeight = '300px';
      } else {
        carouselHeight = '480px';
      }
      return () => window.removeEventListener('resize', resizer);
    },
    [screenWidth]
  );

  const { thumb, ...images } = tour.DisplayImageRemote;
  const filteredImages = Object.values(images)
    .filter(Boolean)
    .map((original) => ({
      original: original,
    }))
    .filter((img) => {
      return img.original.length > 1 && img.original.indexOf('148.jpg') == -1;
    });
  const scrollToSearchWidget = () => {
    setTimeout(() => {
      const offset = scrollRef.current.getBoundingClientRect().top;
      window.scrollTo({
        top: window.scrollY + offset - 90,
        behavior: 'smooth',
      });
    }, 0);
  };
  if (!tour.PriceDescription) {
    tour.PriceDescription = 'per person';
  }

  return (
    <div className={`${classes.wrapper} Tour`}>
      <Header />
      <Carousel
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <div onClick={onClickHandler} className={classes.arrowStyles} style={{ left: 15 }}>
              <img src={arrow_back} alt="Arrow Back"></img>
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <div onClick={onClickHandler} className={classes.arrowStyles} style={{ right: 15 }}>
              <img src={arrow_forward} alt="Arrow Forward"></img>
            </div>
          )
        }
      >
        {filteredImages
          .map((item) => ({ ...item, original: optimizeTourImages(item.original) }))
          .map((item, i) => (
            <div key={i} className={classes.filteredImageContainer}>
              <img className={classes.backgroundImage} src={item.original} alt="Background" />
              <img src={item.original} alt="Background" className={classes.blurredBackground} />
            </div>
          ))}
      </Carousel>
      <div className="book-now-section-wrapper">
        <div className="book-now-section">
          <div className="book-now-left-block">
            <span className="from">{localization.tours.fromOnly}</span>
            <BoldFont className="price">
              {getPriceWithCurrencySymbol(formatPrice(tour.PublishedPrice))}
            </BoldFont>
            <span className="per-unit">
              {localization.common[tour.PriceDescription].replace(/\s/g, '\u00A0') ||
                tour.PriceDescription.replace(/\s/g, '\u00A0') ||
                'per\u00A0person'}
            </span>
          </div>
          <div className="book-now-right-block">
            <CommonButton className={classes.commonButton} onClick={() => scrollToSearchWidget()}>
              {localization.common.bookNow}
            </CommonButton>
          </div>
        </div>
      </div>
      <div className={classes.introContainer}>
        <div className="intro-section">
          <div className="intro-left-block">
            <div className="first-heading">{tour.Title}</div>
            <div className={classes.shortTourDesc}>{tour.Description.short}</div>
            <div style={{ marginBottom: '-16px' }} className="second-heading">
              {localization.tours.about.title}
            </div>
            <TourFeatures tour={tour} />
          </div>
          <div className="intro-right-block">
            <BoldFont className="tour__from">{localization.tours.from}</BoldFont>
            {Number(tour.WasPrice) > Number(tour.PublishedPrice) && (
              <BoldFont className="tour__was-price">
                {getPriceWithCurrencySymbol(formatPrice(Math.ceil(tour.WasPrice)))}
              </BoldFont>
            )}
            <BoldFont className="tour__from-price">
              {getPriceWithCurrencySymbol(formatPrice(tour.PublishedPrice))}
            </BoldFont>
            <BoldFont className="tour__per-unit">
              {localization.common[tour.PriceDescription] || tour.PriceDescription || 'per person'}
            </BoldFont>
          </div>
        </div>
        <div ref={scrollRef}>{bookingWidget}</div>
        <div className="second-heading">{localization.tours.experience.title}</div>

        <Inclusions inclusions={tour.Inclusions} exclusions={tour.Exclusions} />
        <Divider />
        <TourItinerary itinerary={tour.itinerary} />
        <Divider />
        <Description title={localization.tours.experience.descFull} text={tour.Description.long} />
        <Divider />
        <div className="second-heading">{localization.tours.prepareForActivity.title}</div>
        {tour.MeetingPoint && (
          <Map
            title={localization.tours.prepareForActivity.meetingPoint}
            description={tour.MeetingPoint.shortDescription}
            geo={tour.MeetingPoint.geo}
            providerReference={tour.MeetingPoint.providerReference}
          />
        )}
        {tour.MeetingPoint.providerReference && (
          <ViatorMap providerReference={tour.MeetingPoint.providerReference} />
        )}
        <Divider />
        {typeof tour.AdditionalInformation == 'string' ? (
          <div>
            <Text
              title={localization.tours.prepareForActivity.importantInformation}
              text={tour.AdditionalInformation}
            />
            <Divider />
          </div>
        ) : null}
        {reviewWidget}
        <div className="second-heading">{localization.tours.youMightAlsoLike}</div>
        {tour.primaryLocation && (
          <div style={{ textAlign: 'center' }}>
            <LocationBanner
              location={tour.primaryLocation}
              extraTrackingProperties={{
                'display area': 'more to do',
                page: 'tour',
                'page tour name': tour.Title,
                'page tour id': tour.TourID,
              }}
            />
          </div>
        )}
      </div>

      <PoweredBy />
      <Footer />
    </div>
  );
}
