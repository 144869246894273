import React, { useEffect } from 'react';
import 'react-dates/initialize';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FallbackTourModal from '../../components/FallbackTourModal';
import Loader from '../../components/Loader';
import TourDetailsBase from '../../components/TourPage/TourDetailsBase';
import { GygBookingWidget } from '../../components/TourPage/TourOptions';
import TsBookingWidget from '../../components/TourPage/TourOptions/TsBookingWidget';
import ViatorBookingWidget from '../../components/TourPage/TourOptions/ViatorBookingWidget'
import TourReviews from '../../components/TourPage/TourReviews';
import localization from '../../localization';
import { FETCH_TOUR } from '../../store/tour/actionTypes';
import './Tour.scss';

function Tour({ isLoaded, item, match, fetchTour, error, locationId }) {
  //TODO: fix state, this code is 100% spaghet
  const tourId = match.params.tour_id;
  useEffect(() => {
    fetchTour(tourId, localization.getLanguage(), locationId);
  }, [tourId, fetchTour, locationId]);

  if (!isLoaded) {
    return <Loader />;
  }

  if (error) {
    return <FallbackTourModal />;
  }

  //TODO: abstract factory. Hook this stuff to the redux state
  const BookingWidget = item._provider === 'gyg' ? GygBookingWidget : (item._provider === 'viator' ? (ViatorBookingWidget) : TsBookingWidget);
  const reviewWidget = item._provider === 'gyg' || item._provider === 'viator' ? <TourReviews tour_id={tourId} /> : null;

  return (
    <>
      <FallbackTourModal />
      <TourDetailsBase
        tour={item}
        bookingWidget={
          <BookingWidget
            tour={item}
            tourId={tourId}
            languages={item.CondLanguages}
            language={localization.getLanguage()}
            extraTrackingProperties={{
              'display area': 'activity search availablity', 
              page: 'activity',
              'page activity name': item.Title,
              'page activity id': tourId
            }} 
          />
        }
        reviewWidget={reviewWidget}
      />
    </>
  );
}

export default connect(
  state => ({
    item: state.tour.item,
    locationId: state.location?.location?.location_id,
    isLoaded: state.tour.isLoaded,
    error: state.tour.error
  }),
  dispatch => ({
    fetchTour: (tourId, language, locationId) =>
      dispatch({ type: FETCH_TOUR, payload: { tourId, language, locationId } })
  })
)(Tour);
